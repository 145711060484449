import { SaveOutlined, ExportOutlined } from "@ant-design/icons";
import { Button, Layout, Select, Table, Tag, Space, notification } from "antd";
import { Content, Footer } from "antd/es/layout/layout";
import { Header, TableSearch } from "components";
import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllExpenses, updateAllExpenses } from "services/expense-service";
import { formatDecimalNumber } from "utils";
import { EXPENSE_STATUS_DATA } from "utils/static-data";
import { useNavigate } from "react-router-dom";
import PeriodReport from "components/report/period-report";
import {
  createExpenseReport,
  getExpenseReportData
} from "services/expense-report-service";
import { getCompanyBenefits } from "services/company-service";

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});
const DataFetch = {
  data: [],
  loading: false
};

export const ExpenseScreen = (props) => {
  const { login } = props;
  const [data, setData] = React.useState(DataFetch);
  const [initialData, setInitialData] = React.useState(DataFetch);
  const [benefits, setBenefits] = React.useState([]);
  const [canExport, setCanExport] = React.useState(true);

  const navigate = useNavigate();

  const load = async () => {
    setData((prev) => ({ ...prev, loading: true }));
    const res = await getAllExpenses();
    setData((prev) => ({ ...prev, data: res, loading: false }));
    setInitialData((prev) => ({ ...prev, data: res, loading: false }));
    setBenefits(await getCompanyBenefits(true));
  };

  const onReportGeneration = async (
    startPeriod,
    endPeriod,
    getChildrenCompanies,
    getAllPeriod,
    benefits,
    childrens
  ) => {
    setData((prev) => ({ ...prev, loading: true }));
    try {
      const companyData = getChildrenCompanies
        ? login.children.data
            .filter(
              (c) =>
                childrens.filter((ch) => parseInt(ch) == c.id).length > 0 ||
                login.company.id == c.id
            )
            .map((c) => ({ id: c.id, companyName: c.name }))
        : [{ id: login.company.id, companyName: login.company.name }];
      const expenseReportData = await getExpenseReportData(
        companyData,
        startPeriod,
        endPeriod,
        getAllPeriod,
        benefits
      );
      await createExpenseReport(expenseReportData);
    } catch (e) {
      notification.error({ message: e.message });
    }
    setData((prev) => ({ ...prev, loading: false }));
  };

  const formatAddress = (record) =>
    record.city
      ? [
          record.street,
          record.postalCode,
          record.neighborhood,
          record.city,
          record.state,
          record.country
        ]
          .filter((x) => x)
          .join(", ")
      : "";

  useEffect(() => {
    load();
  }, []);
  return (
    <Layout className="alymente-layout">
      <Header
        title="Comprovantes de gastos"
        // routes={[{ label: "Relatório", route: "/expense/report" }]}
      />
      <Content>
        <Table
          scroll={{ x: 1000, y: "60vh" }}
          pagination={false}
          dataSource={data.data}
          loading={data.loading}
          initialData={initialData.data}
          bordered
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
              }}
            >
              <TableSearch
                data={data}
                initialData={initialData.data}
                filter={[
                  "transactionId",
                  "status.name",
                  "value",
                  "mileage",
                  "litersFuel",
                  "transactionAt"
                ]}
                onSearch={(e) => {
                  setData((prev) => ({ ...prev, data: e }));
                }}
              />
            </div>
          )}
        >
          <Table.Column
            width={200}
            align="center"
            title="Data da Transação"
            dataIndex={"transactionAt"}
            key="data.transactionAt"
            render={(value) => moment(value).format("DD/MM/YYYY HH:mm:ss")}
          />
          <Table.Column
            width={200}
            align="center"
            title="Data da Criação do Comprovante"
            dataIndex={"createdAt"}
            key="data.createdAt"
            render={(value) => moment(value).format("DD/MM/YYYY HH:mm:ss")}
          />
          <Table.Column
            width={350}
            title="Colaborador"
            dataIndex={["employee", "name"]}
            key="userSex.code"
          />
          <Table.Column
            width={150}
            title="Benefício"
            dataIndex={["benefit", "name"]}
            key="data.benefit.name"
          />

          <Table.Column
            width={200}
            title="Descrição"
            dataIndex="name"
            key="data.name"
          />
          <Table.Column
            width={300}
            align="center"
            title="Endereço"
            key="data.address"
            render={(_, record) => formatAddress(record)}
          />
          <Table.Column
            width={180}
            align="center"
            title="Status"
            dataIndex={["status", "name"]}
            key="data.status.name"
            render={(value, record) => (
              <Select
                style={{ width: "100%" }}
                value={value}
                onChange={(value) =>
                  setData((data) => {
                    setCanExport(false);
                    const codeStatus = EXPENSE_STATUS_DATA.filter(
                      (e) => e.name == value
                    );
                    const newData = data.data.map((item) =>
                      item.transactionId === record.transactionId
                        ? {
                            ...item,
                            status: codeStatus[0]
                          }
                        : item
                    );
                    return { ...data, data: newData };
                  })
                }
              >
                {EXPENSE_STATUS_DATA.map((item) => (
                  <Select.Option key={item.name} value={item.name}>
                    <Tag
                      color={item.category}
                      bordered={false}
                      style={{ background: "none", fontSize: "14px" }}
                    >
                      {item.name}
                    </Tag>
                  </Select.Option>
                ))}
              </Select>
            )}
          />

          <Table.Column
            width={150}
            title="Valor"
            align="right"
            dataIndex={"value"}
            key="data.value"
            render={(value) => `R$ ${formatDecimalNumber(value, 2)}`}
          />

          <Table.Column
            width={200}
            align="right"
            title="Quilometragem"
            dataIndex={"mileage"}
            key="data.mileage"
            render={(value, record) =>
              value ? `${formatDecimalNumber(value, 0)} km` : ""
            }
          />
          <Table.Column
            width={200}
            align="right"
            title="Litros"
            dataIndex={"litersFuel"}
            key="data.litersFuel"
            render={(value, { precision }) =>
              value ? `${formatDecimalNumber(value, precision)} L` : ""
            }
          />
          <Table.Column
            width={200}
            align="right"
            title="Valor / Litro"
            dataIndex={"litersFuelValue"}
            key="data.litersFuelValue"
            render={(value, { precision }) =>
              value ? `R$ ${formatDecimalNumber(value, 2)}` : ""
            }
          />
          <Table.Column
            width={150}
            align="center"
            title="Tipo"
            dataIndex={"fuelTypeName"}
            key="data.fuelTypeName"
            render={(value, { precision }) =>
              value && value != "" ? value : ""
            }
          />
          <Table.Column
            width={150}
            align="center"
            title="Placa Veículo"
            dataIndex={"licensePlate"}
            key="data.licensePlate"
            render={(value, { precision }) =>
              value && value != "" ? value : ""
            }
          />
          <Table.Column
            fixed="right"
            width={120}
            align="center"
            title="Ações"
            dataIndex="id"
            key="id"
            render={(value) => (
              <Button
                size="small"
                type={"link"}
                onAuxClick={(e) => window.open("/expense/" + value)}
                onClick={() => navigate(`/expense/${value}`)}
              >
                Detalhes
              </Button>
            )}
          />
        </Table>
      </Content>

      <Footer>
        <Space align="end">
          {canExport && (
            <PeriodReport
              childrenCompaniesConfig={{ visible: true }}
              loading={data.loading}
              onReportGeneration={onReportGeneration}
              isVisible={!login.company.master && canExport}
              buttonConfig={{ title: "Exportar", icon: <ExportOutlined /> }}
              getAllDateConfig={{ visible: true }}
              screen={"expense"}
              benefits={benefits}
              childrens={login.children.data}
              company={login.company}
            />
          )}

          <Button
            loading={data.loading}
            className="btn-save"
            type="primary"
            icon={<SaveOutlined />}
            onClick={async () => {
              setCanExport(true);
              const modifiedItem = data.data.filter((item) => {
                const initialItem = initialData.data.find(
                  (initialItem) =>
                    initialItem.transactionId === item.transactionId
                );
                return initialItem?.status.name !== item?.status.name;
              });
              if (modifiedItem.length > 0) {
                setData((prev) => ({ ...prev, loading: true }));
                await updateAllExpenses(modifiedItem);
                setData((prev) => ({ ...prev, loading: false }));
                setInitialData((prev) => ({
                  ...prev,
                  data: data.data,
                  loading: false
                }));
              }
            }}
          >
            Salvar
          </Button>
        </Space>
      </Footer>
    </Layout>
  );
};
export default connect(mapStateToProps, {})(ExpenseScreen);
