import { SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Layout,
  notification,
  Row,
  Select,
  Spin
} from "antd";
import { Form, Header, InputCurrency } from "components";
import React, { useEffect } from "react";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ADVANCE_STATUS, ADVANCE_TYPE } from "utils/static-data";

import { DatePicker } from "components";
import moment from "moment";
import { getAdvanceById, updateAdvance } from "services/advance-service";
import { handlerError } from "utils";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const AdvanceDetailsScreen = () => {
  const { id = null } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = React.useState(null);
  const [form] = Form.useForm();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [initialStatus, setInitialStatus] = React.useState(null);

  const load = async () => {
    setLoading(true);
    if (id) {
      let res = await getAdvanceById(id);

      if (res) {
        setData(res);
        setStatus(res.status);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (ADVANCE_STATUS[status] !== ADVANCE_STATUS.denied)
      form.setFieldValue("reasonDenial", "");
  }, [status]);

  const onSave = async (values) => {
    try {
      setLoading(true);
      await updateAdvance(data.id, values.status, values.reasonDenial);
      notification.success({ message: "Atualizado com sucesso!" });
      navigate("/advance");
    } catch (e) {
      handlerError(e);
    } finally {
      setLoading(false);
    }
  };

  const loadFields = () => {
    const advance = data;
    const formData = {
      ...advance,
      employeeName: advance.employee.name,
      type: ADVANCE_TYPE[advance.type],
      createdAt: moment(advance.createdAt)
    };

    if (ADVANCE_TYPE[data.type] === ADVANCE_TYPE.traveling) {
      formData.startDate = moment(advance.startDate);
      formData.endDate = moment(advance.endDate);
    }
    setInitialStatus(advance.status);
    form.setFieldsValue(formData);
  };

  React.useEffect(() => {
    load();
  }, [id]);

  React.useEffect(() => {
    if (data) {
      loadFields();
    }
  }, [data]);

  return (
    <Layout className="alymente-layout">
      <Header title={`Adiantamento - ${data?.employee.name}`} />
      <Content>
        <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
          <Row justify="center" gutter={24}>
            <Col xl={18} lg={20} md={24}>
              <Card title="Detalhes do Adiantamento">
                <Spin spinning={loading}>
                  <Row gutter={24}>
                    {
                      <React.Fragment>
                        <Col lg={18} md={12} sm={24}>
                          <Form.Item label="Colaborador" name={"employeeName"}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={6} md={12} sm={24}>
                          <Form.Item
                            label="Status"
                            name={"status"}
                            rules={[
                              { required: true, message: "Insira um status" }
                            ]}
                          >
                            <Select
                              placeholder="Selecione um status"
                              allowClear
                              disabled={
                                ADVANCE_STATUS[initialStatus] !==
                                ADVANCE_STATUS.pending
                              }
                              onChange={(value) => {
                                setStatus(value);
                              }}
                            >
                              {Object.entries(ADVANCE_STATUS)
                                .filter(
                                  ([_, status]) =>
                                    ADVANCE_STATUS[initialStatus] !==
                                      ADVANCE_STATUS.pending ||
                                    (status != ADVANCE_STATUS.finished &&
                                      status != ADVANCE_STATUS.redeem)
                                )
                                .map(([key, item]) => {
                                  return (
                                    <Select.Option key={key} value={key}>
                                      {item}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} sm={24}>
                          <Form.Item label="Data da Criação" name={"createdAt"}>
                            <DatePicker
                              style={{ width: "100%" }}
                              format="DD/MM/YYYY"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        {ADVANCE_TYPE[data?.type] == ADVANCE_TYPE.traveling && (
                          <>
                            <Col lg={8} md={12} sm={24}>
                              <Form.Item
                                label="Data do Início"
                                name={"startDate"}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                              </Form.Item>
                            </Col>

                            <Col lg={8} md={12} sm={24}>
                              <Form.Item label="Data do Fim" name={"endDate"}>
                                <DatePicker
                                  style={{ width: "100%" }}
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        <Col lg={8} md={12} sm={24}>
                          <Form.Item label="Tipo" name={"type"}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={8} md={12} sm={24}>
                          <Form.Item label="Valor" name={["amount"]}>
                            <InputCurrency disabled autoComplete="off" />
                          </Form.Item>
                        </Col>
                        {data?.redeemAmount && (
                          <Col lg={8} md={12} sm={24}>
                            <Form.Item
                              label="Valor Resgatado"
                              name={"redeemAmount"}
                            >
                              <InputCurrency disabled />
                            </Form.Item>
                          </Col>
                        )}
                        <Col md={24}>
                          <Form.Item label="Descrição" name={"description"}>
                            <Input.TextArea
                              disabled
                              rows={3}
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        {ADVANCE_STATUS[status] === ADVANCE_STATUS.denied && (
                          <Col md={24}>
                            <Form.Item
                              label="Motivo de recusa"
                              name={"reasonDenial"}
                            >
                              <Input.TextArea
                                disabled={
                                  ADVANCE_STATUS[data?.status] ===
                                    ADVANCE_STATUS.denied &&
                                  form.getFieldValue("reasonDenial")
                                }
                                rows={3}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </React.Fragment>
                    }
                  </Row>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Form>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          disabled={loading}
          loading={loading}
          type="primary"
          icon={<SaveOutlined />}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(AdvanceDetailsScreen);
