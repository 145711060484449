import { notification } from "antd";
import axios from "axios";
import { handlerError, formatDate } from "utils";

export const getAllExpenses = async () => {
  try {
    const { data } = await axios.get(`/api/expense`);
    return data.data;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};
export const updateAllExpenses = async (data) => {
  try {
    const res = await axios.put(`/api/expense/status`, { expenses: data });
    notification.success({ message: "Salvo com sucesso" });
    return res.data;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getExpenseReport = async (filter) => {
  try {
    const params = {};
    if (filter?.benefit) {
      params.benefit = filter.benefit;
    }
    if (filter?.dates && filter.dates.length === 2) {
      params.startDate = filter.dates[0].format("YYYY-MM-DD");
      params.endDate = filter.dates[1].format("YYYY-MM-DD");
    }
    const { data } = await axios.get(`/api/expense/report`, { params: params });
    return (
      data.data
        .map((item) => ({ ...item, balance: null }))
        .sort((a, b) => a.employee.name.localeCompare(b.employee.name)) || []
    );
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getExpenseById = async (id) => {
  try {
    const { data } = await axios.get(`/api/expense/${id}`);
    return data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const retryGetAddress = async (id, postalCode) => {
  try {
    const { data } = await axios.put(
      `/api/expense/${id}/${postalCode}/address`
    );
    return data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};
export const getExpensesByCompanyIdAndPeriod = async (
  companyId,
  startPeriod,
  endPeriod,
  getAllPeriod,
  benefits = []
) => {
  const params = {};
  if (!getAllPeriod) {
    params.startPeriod = startPeriod;
    params.endPeriod = endPeriod;
  }
  params.benefits = benefits
  try {
    const { data } = await axios.post(`/api/expense/company/${companyId}`, {
      ...params
    });
    const { data: expenseReport } = data;
    return expenseReport;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const updateExpense = async (id, body) => {
  try {
    const res = await axios.put(`/api/expense/${id}`, body);
    notification.success({ message: "Salvo com sucesso" });
    return res.data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};
